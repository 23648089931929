import * as React from "react"

export default class ContactForm extends React.Component {
	state = {
	  name: "",
		email: "",
		message: "",
		to: "office@barrelhouse.rs",
		from: "sajt-kontakt@barrelhouse.rs",
		sending: false,
		sent: false,
	}

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
		this.setState(prevState => {
			return {
				...prevState,
				sending: true,
			}
		})
    return fetch(`https://oo.co.rs/handle-form.php`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(this.state),
				mode: 'no-cors',
			})
      .then(response => {
/*
				if (!response.ok) {
					//throw new Error('Network response was not ok')
					throw new Error(response.status)
				}
*/
				return response
			})
      //.then(response => response.json()) // parse JSON from request
      .then(resultData => {
				this.setState(prevState => {
					return {
						...prevState,
						sending: false,
						sent: true,
					}
				})
      })
/*
    event.preventDefault()
    alert(`Welcome ${this.state.name} ${this.state.email}!`)
*/
  }

  render() {
	if (this.state.sent === true) {
		return <h3>Hvala. Poruka je poslata.</h3>
	} else if(this.state.sending === true) {
		return <p>Poruka se šalje...</p>
	} else {
    return (
			<form onSubmit={this.handleSubmit} className="contacts--cube__form">
				<div className={`form-item-wrap ${this.state.name ? 'bar--show' : ''}`}>
					<input
						required="true"
						id="input_name"
						type="text"
						name="name"
						value={this.state.name}
						onChange={this.handleInputChange}
						className={this.state.name ? 'active' : ''}
					/>
					<label htmlFor="input_name">
						<span className="placeholder">Ime</span>
					</label>
					<span className="bar"></span>
				</div>
				<div className={`form-item-wrap ${this.state.email ? 'bar--show' : ''}`}>
					<input
						required="true"
						id="input_email"
						type="email"
						name="email"
						value={this.state.email}
						onChange={this.handleInputChange}
						className={this.state.email ? 'active' : ''}
					/>
					<label htmlFor="input_email">
						<span className="placeholder">Email</span>
					</label>
					<span className="bar"></span>
				</div>
				<div className={`form-item-wrap ${this.state.message ? 'bar--show' : ''}`}>
					<textarea
						required="true"
						id="textarea_message"
						cols="30"
						rows="5"
						name="message"
						value={this.state.message}
						onChange={this.handleInputChange}
						className={this.state.message ? 'active' : ''}
					></textarea>
					<label htmlFor="textarea_message">
						<span className="placeholder">Poruka</span>
					</label>
					<span className="bar"></span>
				</div>
				<div className="text-center text-sm-right">
					<input className="btn -accent -md" type="submit" value="šalji" />
				</div>
			</form>
		)
  }
  }
}
