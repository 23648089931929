import * as React from "react"
import {Helmet} from "react-helmet"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import BHCorner from "../images/bh-corner.svg"
import BHLogo from "../images/symbol-original.png"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import ContactForm from "../components/ContactForm"
import { SEO } from "../components/seo"
import Map, { Marker } from 'react-map-gl/maplibre'
import 'maplibre-gl/dist/maplibre-gl.css'

const isClient = typeof window !== 'undefined'

const IndexPage = () => {

  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "pocetna-hero-bez-teksta.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <>
    <Layout
      bodyClasses={" main-page flat-menu flat-menu-page flat-page meny-right "}
			bodyStyles={{backgroundColor: "#F7F7F7"}}
      headerClasses={" -main-page "}
      showBookButton={true}
      hideFooter={true}
    >
			<Helmet>
				<style type="text/css">
					{`
						body {
						  background-color: #F7F7F7 !important;
						}
					`}
				</style>
			</Helmet>
      <div className="page home--cube -white-second-bg -section -section-centering" id="home">
				<BackgroundImage
					Tag="div"
					className="welcome-block"
					fluid={data.desktop.childImageSharp.fluid}
					backgroundColor="#cc8648"
					style={{
						position: 'absolute',
						backgroundPosition: '',
					}}
				>
          <div className="container">
            <div className="row">
              <div className="col-md-11 col-xl-11 offset-md-1 offset-xl-0">
                <div className="main-title-block">
                  <h1 className="main-title-of-site">Barrel House</h1>
                  <p className="sub-title">restaurant</p>
                  <p className="tag-line">prvoklasni užitak mora da odleži</p>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>

        <ul className="social-icons -for-mobile -vertical -md -accent">
          <li><a href="https://www.instagram.com/barrelhousebelgrade/" target="_blank" rel="noreferrer"><span className="icon-social_instagram"></span></a></li>
          <li><a href="https://www.facebook.com/barrelhousebg" target="_blank" rel="noreferrer"><span className="icon-social_facebook"></span></a></li>
          <li><a href="https://www.tripadvisor.com/Restaurant_Review-g294472-d17813343-Reviews-Barrel_House-Belgrade.html" target="_blank" rel="noreferrer"><span className="icon-social_tripadvisor"></span></a></li>
        </ul>

      </div>

      <div className="page our-story--cube -gray-bg -section" id="our-story">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h2 className="title-section -left-shift">
                <span className="title-decoration" style={{width: "36px"}}><img alt="" style={{width: "24px"}} src={BHCorner} /></span>
                Priča počinje...
              </h2>
              <div className="text-block -alignment -text-block-margin intro-text">
                <p>Naša istorija je kao čašica vrhunske rakije.</p>
                <p>Kratka, ali ispunjava toplinom od glave do pete.</p>
              </div>
              <div className="text-left -alignment">
                <Link to="o-nama" className="btn -accent -md page-redirect" style={{marginBottom: '2rem'}}>naša priča</Link>
              </div>
            </div>

            <div className="col-12 col-md-8 col-lg-6">
              <div style={{position: 'relative', paddingBottom: '56.25%', height: '0', backgroundColor: '#e7e7e7'}}>
                <iframe style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}} width="560" height="315" src="https://www.youtube.com/embed/bHYcYVw2P_E" title="YouTube video player" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="-section">
				<div className="container">
					<div className="main-gallery-section -bottom-indent">
            <div className="tab-content main-gallery-tabs-wrap -bottom-indent">
              <div className={`tab-pane h-100 gallery-wrap show"}`}  style={{display: "block"}} id="all" role="tabpanel" aria-labelledby="all-tab">

								<div className="row small-gutters-fix">
                  <div className="col-12 col-sm-6 col-md-3">
                    <Link to="/restoran" className="square-photo-wrap gallery-nav-img-wrap_link">
                      <span className="square-photo">
                        <StaticImage
                          src="../images/pocetna-link-restoran.jpg"
                          alt=""
                          width={539}
                          placeholder="blurred"
                        />
                        <span className="overlay home-tabs-overlay">
                          <span className="subtitle">Novi ukusi na starom Dorćolu</span>
                          <span className="small-title">RESTORAN</span>
                        </span>
                      </span>
                      <span className="border-animation-top"></span>
                      <span className="border-animation-left"></span>
                      <span className="border-animation-bottom"></span>
                      <span className="border-animation-right"></span>
                    </Link>
                  </div>

                  <div className="col-12 col-sm-6 col-md-3">
                    <Link to="/dogadjaji" className="square-photo-wrap gallery-nav-img-wrap_link">
                      <span className="square-photo">
                        <StaticImage
                          src="../images/pocetna-link-dogadjaji.jpg"
                          alt=""
                          width={539}
                          placeholder="blurred"
                        />
                        <span className="overlay home-tabs-overlay">
                          <span className="subtitle">Svaki dan je događaj, ako je na pravom mestu</span>
                          <span className="small-title">DOGAĐAJI</span>
                        </span>
                      </span>
                      <span className="border-animation-top"></span>
                      <span className="border-animation-left"></span>
                      <span className="border-animation-bottom"></span>
                      <span className="border-animation-right"></span>
                    </Link>
                  </div>

                  <div className="col-12 col-sm-6 col-md-3">
                    <Link to="/vencanja" className="square-photo-wrap gallery-nav-img-wrap_link">
                      <span className="square-photo">
                        <StaticImage
                          src="../images/pocetna-link-vencanja.jpg"
                          alt=""
                          width={539}
                          placeholder="blurred"
                        />
                        <span className="overlay home-tabs-overlay">
                          <span className="subtitle">Savršenstvo. Od prvog plesa, do prvih petlova.</span>
                          <span className="small-title">VENČANJA</span>
                        </span>
                      </span>
                      <span className="border-animation-top"></span>
                      <span className="border-animation-left"></span>
                      <span className="border-animation-bottom"></span>
                      <span className="border-animation-right"></span>
                    </Link>
                  </div>

                  <div className="col-12 col-sm-6 col-md-3">
                    <a href="https://www.belgrade-distillery.com/" target="_blank" rel="noreferrer" className="square-photo-wrap gallery-nav-img-wrap_link">
                      <span className="square-photo">
                        <StaticImage
                          src="../images/pocetna-link-destilerija.jpg"
                          alt=""
                          width={539}
                          placeholder="blurred"
                        />
                        <span className="overlay home-tabs-overlay">
                          <span className="subtitle">Jedinstveni koncept zanatske destilerije</span>
                          <span className="small-title">DESTILERIJA</span>
                        </span>
                      </span>
                      <span className="border-animation-top"></span>
                      <span className="border-animation-left"></span>
                      <span className="border-animation-bottom"></span>
                      <span className="border-animation-right"></span>
                    </a>
                  </div>
								</div>

							</div>
						</div>
					</div>
				</div>
      </div>

			<div className="page contacts--cube -white-bg -section" id="contacts">
				<div className="container">
					<div className="row">
						<div className="col-12 col-sm-6 col-md-4 col-xl-3">
							<h2 className="title-section -left-shift">
								<span className="title-decoration"></span>
								Kontakt
							</h2>

							<address className="-alignment">
								<ul className="contacts-list">
									<li>
										<span className="icon-arrows contacts-list-icon"></span>
                    <a href="https://www.google.com/maps/place/Barrel+House/@44.8218149,20.4654594,17z/data=!4m14!1m8!3m7!1s0x475a7ab619392363:0x476f640a40f91dcc!2s%C5%BDor%C5%BEa+Klemansoa+19,+Beograd+11000!3b1!8m2!3d44.8221345!4d20.4685278!10e3!3m4!1s0x475a7b8fb992c3fb:0x3688917e4e981e5d!8m2!3d44.8221755!4d20.4688047" target="_blank" rel="noreferrer">Žorža Klemansoa 19, Beograd</a>
									</li>
									<li>
										<span className="icon-telephone contacts-list-icon"></span>
                    <a href="tel:00381604466039">060/44-66-039</a>
									</li>
									<li>
										<span className="icon-envelope contacts-list-icon"></span>
										<a href="mailto:office@barrelhouse.rs">office@barrelhouse.rs</a>
									</li>
									<li>
										<span className="icon-envelope contacts-list-icon"></span>
										<a href="mailto:vencanja@barrelhouse.rs">vencanja@barrelhouse.rs</a>
									</li>
								</ul>
							</address>

    {/*
							<div className="subscribe-block -alignment">
								<h3 className="small-title">Želite newsletter?</h3>
								<form className="contacts--cube__subscribe">
									<div className="form-item-wrap">
										<input id="email__subscribe" type="email" />
										<label htmlFor="email__subscribe">
											<span className="placeholder">Email</span>
										</label>
										<span className="bar"></span>
									</div>
									<input id="email-subscribe-icon" type="submit" />
									<label className="submit-icon" htmlFor="email-subscribe-icon">
										<span className="icon-send-button"></span>
									</label>
								</form>
							</div>
            */}
						</div>

						<div className="col-12 col-sm-6 col-md-3 offset-0 offset-xl-1 map-col" style={{position: 'relative'}}>
							<div className="map-container">
								<div className="map-wrap">

									<div style={{width: '100%', position: 'absolute', top: '0', bottom: '0'}}>

                    { isClient && (
                      <Map
                        initialViewState={{
                          longitude: 20.468796018872233,
                          latitude: 44.82232853241051,
                          zoom: 16
                        }}
                        mapStyle="https://maps.geoapify.com/v1/styles/klokantech-basic/style.json?apiKey=0d5a20e42aa14d5cb8f5ce33a97d292b"
                        attributionControl={false}
                      >
                        <Marker
                          longitude={20.468796018872233}
                          latitude={44.82232853241051}
                          color="red"
                        >
                          <div className="marker-wrapper">
                            <img alt="Barrel house logo" src={BHLogo} />
                          </div>
                        </Marker>
                      </Map>
                    )}


                  </div>

    {/*
									<span className="icon-full-screen map-icon"></span>
                */}
								</div>
							</div>
						</div>

						<div className="col-12 col-md-5 col-xl-4 offset-0 offset-xl-1">
							<div className="form-content">
								<h3 className="small-title">Pošaljite nam poruku</h3>
								<ContactForm />
							</div>
						</div>
					</div>

					<p className="small-text copyright -alignment">© 2021 Barrel House. Sva prava zadržana.</p>
				</div>
			</div>

      <ul className="social-icons social-icons--with-fixed -md -vertical -fixed -accent">
          <li><a href="https://www.instagram.com/barrelhousebelgrade/" target="_blank" rel="noreferrer"><span className="icon-social_instagram"></span></a></li>
          <li><a href="https://www.facebook.com/barrelhousebg" target="_blank" rel="noreferrer"><span className="icon-social_facebook"></span></a></li>
          <li><a href="https://www.tripadvisor.com/Restaurant_Review-g294472-d17813343-Reviews-Barrel_House-Belgrade.html" target="_blank" rel="noreferrer"><span className="icon-social_tripadvisor"></span></a></li>
      </ul>

    </Layout>
    </>
  )
}

export default IndexPage

export const Head = () => (
  <SEO />
)
